import { getText, getImage, getDropdown, getTabName} from "./getContent"
import { headHtml } from "./head"
import { footerHtml } from "./footer"

export const traceabilityHtml = (tab, cardLangs,lang, tabsName) => {
console.log('tabsName', tabsName)
  return  headHtml +`
  
  <div class="page-content">

  ${getDropdown(cardLangs,lang, 'tracabilite')}

  <div class="content-top is-traceability">
      <div class="wine-top-infos">
          <h3 class="wine-name">${getText(tab,1)}</h3>
          <div class="wine-infos-1">${getText(tab,2)}</div>
          <div class="wine-infos-2">${getText(tab,3)}</div>
      </div>
      <div class="caption-image">
          <img src="../../images/tracabilite.png">
          <h5>${getText(tab,4)}</h5>
      </div>
  </div>

  <div id="track"></div>


  <div class="menu-hider"></div>
</div>

<div class="footer-menu footer-5-icons footer-menu-center-icon text-center">
<a id="index"  href="index.html">
    <i class="icon_accueil"></i><span>${getTabName(tabsName, 1)}</span>
</a>
<a id="propriete" href="propriete.html">
    <i class="icon_propriete"></i><span>${getTabName(tabsName, 2)}</span>
</a>
<a id="service" href="service.html">
    <i class="icon_services"></i><span>${getTabName(tabsName, 3)}</span>
</a>
<a id="tracabilite"  class="selected-item" href="tracabilite.html">
    <i class="icon_tracabilite"></i><span>${getTabName(tabsName, 4)}</span>
</a>
<a id="documents" href="documents.html">
    <i class="icon_documents"></i><span>${getTabName(tabsName, 5)}</span>
</a>
</div>


`

+ footerHtml +

`
<script>

    $(document).ready(function () {

        const urlParams = new URLSearchParams(window.location.search);
        const serial = urlParams.get('s')
        console.log("Serial: " + serial);

        document.getElementById("index").href = "index.html?s=" + serial;
        document.getElementById("propriete").href = "propriete.html?s=" + serial;
        document.getElementById("service").href = "service.html?s=" + serial;
        document.getElementById("tracabilite").href = "tracabilite.html?s=" + serial;
        document.getElementById("documents").href = "documents.html?s=" + serial;

        $("#track").empty();

        $.ajax({
            url: wibURL() + '/GetTagHistory?s=' + serial,
            type: 'GET',
            processData: false,
            contentType: false,
            async: false,
            success: function (responseJson) {
                console.log("Response: " + responseJson.data);
                for (var i = 0; i < responseJson.data.length; i++) {
                    var dt = responseJson.data[i];
                    var track_dir = document.getElementById("track");
                    if (dt.country != "")
                        $("#track").append("<div class=\\"content-wib top-20 content-boxed round-large content-white\\"><div class=\\"one-half small-half\\" ><div class=\\"container-wib center\\"><img src=\\"../../images/track.png\\" class=\\"center responsive-image\\" style=\\"width: 50%;margin-top: 15px !important;margin-bottom: 15px !important;\\"/><div class=\\"text-center font-11\\" style=\\"top: -60px;line-height: 100%;left: 0px;width: 50%;color: #FFF;\\">" + dt.date + "</div></div></div><div style=\\"margin-top: 25px;margin-bottom: 15px;\\" class=\\"one-half large-half last-column\\"><span class=\\"under-heading font-12\\" style=\\"color: #CB5822;\\">" + dt.city + "</span><span class=\\"under-heading font-12 color-grey\\">(" + dt.country + ")</span><span class=\\"under-heading font-12 color-grey\\" style=\\"margin-top: 1px !important;\\">" + dt.action + "</span></div></div>");
                    else
                        $("#track").append("<div class=\\"content-wib top-20 content-boxed round-large content-white\\"><div class=\\"one-half small-half\\" ><div class=\\"container-wib center\\"><img src=\\"../../images/track.png\\" class=\\"center responsive-image\\" style=\\"width: 50%;margin-top: 15px !important;margin-bottom: 15px !important;\\"/><div class=\\"text-center font-11\\" style=\\"top: -60px;line-height: 100%;left: 0px;width: 50%;color: #FFF;\\">" + dt.date + "</div></div></div><div style=\\"margin-top: 25px;margin-bottom: 15px;\\" class=\\"one-half large-half last-column\\"><span class=\\"under-heading font-12\\" style=\\"color: #CB5822;\\">" + dt.city + "</span><span class=\\"under-heading font-12 color-grey\\">(Localisation inconnue)</span><span class=\\"under-heading font-12 color-grey\\" style=\\"margin-top: 1px !important;\\">" + dt.action + "</span></div></div>");

                    console.log(dt.date);
                    console.log(dt.action);
                    console.log(dt.country);
                    console.log(dt.city);
                }
            }
        });
    });

</script>




</html>`


};
