import { cardLanguages } from "../utils/cardLanguages";

export const getText = (contentTab, position) => {
  if(contentTab){
    const contentText = contentTab.filter((obj) =>
    obj.hasOwnProperty("id_wic_content_text")
  );
  const text = contentText.filter((obj) => obj.position === position);
  return text.length > 0 ? text[0].content : "";
  }else{
    return '';
  }

};
export const getTabName = (tabsName, position) => {

  if(tabsName){
    const tab = tabsName.filter((obj) => obj.position === position)[0];
  return tab.name;
  }else{
    return '';
  }

};

export const getImage = (contentTab, position) => {
  if(contentTab){
  const contentImages = contentTab.filter((obj) =>
    obj.hasOwnProperty("id_wic_content_image")
  );
  const image = contentImages.filter((obj) => obj.position === position);
  return image.length > 0 ? `<img src="./images/${image[0].filename}">` : ``;

  }else{
    return '';
  }
};
export const getMainImage = (contentTab, position) => {
  if(contentTab){
  const contentImages = contentTab.filter((obj) =>
    obj.hasOwnProperty("id_wic_content_image")
  );
  const image = contentImages.filter((obj) => obj.position === position);
  return image.length > 0 ? `<img src="./images/${image[0].filename}">` : ``;

  }else{
    return `<img src="./images/default-wineinblock.png">`;
  }
};

export const getDropdown = (cardLangs, lang, htmlName) => {


  const filteredObj = cardLanguages.filter((item) => {
    return cardLangs.some((element) => {
      return item.flag === element.url_flag;
    });
  });

  const currentLang = filteredObj.filter((item) => item.code == lang)[0];
  const langOptions = filteredObj.filter((item) => item.code != lang);

  let stringOptions = ``;
  for (let i = 0; i < langOptions.length; i++) {
    const lang = langOptions[i];
    stringOptions +=  `<a href="../${lang.code}/${htmlName}.html"><span class="flag ${lang.flag}"></span>${lang.name}</a>`;
  }


  return `<div class="dropdown">
    <button onclick="toggleDropdown()" class="dropbtn"><span class="arrow"> </span> 
    <span class="flag ${currentLang.flag}"></span>${currentLang.name}</button>
    <div id="myDropdown" class="dropdown-content">
        ${stringOptions} 
    </div>
</div>`;
};
