import { getText, getImage, getDropdown, getTabName} from "./getContent"
import { headHtml } from "./head"
import { footerHtml } from "./footer"

export const serviceHtml = (tab, cardLangs,lang, tabsName) => {

  return  headHtml +` <div class="page-content">

  <div class="page-content">

  ${getDropdown(cardLangs,lang, 'service')}

  <div class="content-top">
      <div class="wine-top-infos">
          <h3 class="wine-name">${getText(tab, 1)}</h3>
          <div class="wine-infos-1">${getText(tab, 2)}</div>
          <div class="wine-infos-2">${getText(tab, 3)}</div>
      </div>
      <div class="caption-image">
          <img src="../../images/services.png">
          <h5>${getText(tab, 4)}</h5>
      </div>
  </div>

  <div class="content-box">
      <h6>${getText(tab,5)}</h6>
      <p>${getText(tab,6)}</p>
  </div>


  <div class="tasting-box">
      <img src="../../images/garde.png">
      <div class="tasting-infos">
          <h5>${getText(tab,7)}</h5>
          <p>${getText(tab,8)}</p>
      </div>
  </div>

  <div class="tasting-box">
      <img src="../../images/temperature.png">
      <div class="tasting-infos">
          <h5>${getText(tab,9)}</h5>
          <p>${getText(tab,10)}</p>
      </div>
  </div>

  <div class="tasting-box">
      <img src="../../images/carafage.png">
      <div class="tasting-infos">
          <h5>${getText(tab,11)}</h5>
          <p>${getText(tab,12)}</p>
      </div>
  </div>

  <div class="tasting-box">
      <img src="../../images/temperature.png">
      <div class="tasting-infos">
          <h5>${getText(tab,13)}</h5>
          <p>${getText(tab,14)}</p>
      </div>
  </div>


  <div class="content-image">
  ${getImage(tab,15)}
  </div>

  <div class="menu-hider"></div>
</div>


<div class="footer-menu footer-5-icons footer-menu-center-icon text-center">
<a id="index" href="index.html">
    <i class="icon_accueil"></i><span>${getTabName(tabsName, 1)}</span>
</a>
<a id="propriete" href="propriete.html">
    <i class="icon_propriete"></i><span>${getTabName(tabsName, 2)}</span>
</a>
<a id="service" class="selected-item" href="service.html">
    <i class="icon_services"></i><span>${getTabName(tabsName, 3)}</span>
</a>
<a id="tracabilite" href="tracabilite.html">
    <i class="icon_tracabilite"></i><span>${getTabName(tabsName, 4)}</span>
</a>
<a id="documents"  href="documents.html">
    <i class="icon_documents"></i><span>${getTabName(tabsName, 5)}</span>
</a>
</div>


`

+ footerHtml +

`
<script>

$(document).ready(function () {

    const urlParams = new URLSearchParams(window.location.search);
    const serial = urlParams.get('s')
    console.log("Serial: " + serial);

    document.getElementById("index").href = "index.html?s=" + serial;
    document.getElementById("propriete").href = "propriete.html?s=" + serial;
    document.getElementById("service").href = "service.html?s=" + serial;
    document.getElementById("tracabilite").href = "tracabilite.html?s=" + serial;
    document.getElementById("documents").href = "documents.html?s=" + serial;
});

</script>




</html>`


};
