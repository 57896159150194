import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const ModalConfirmation = ({
  closeModal,
  infoText,
  noValidation,
  customClass,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`modal-bg  modal-confirmation ${customClass}`}>
      <div className="modal">
        <div
          className="close-modal"
          onClick={() => {
            closeModal(false);
          }}
        ></div>

        <h1 className="title-h1">{infoText}</h1>

        {noValidation ? (
          <div className="section-cta">
            <input
              className="create-bt cta-primary"
              type="submit"
              value="OK"
              onClick={() => {
                closeModal(true);
              }}
            />
          </div>
        ) : (
          <div className="section-cta">
            <div
              className="cancel-bt cta-tertiary"
              onClick={() => {
                closeModal(false);
              }}
            >
              {t("cancel")}
            </div>
            <input
              className="create-bt cta-primary"
              type="submit"
              value={t("yes")}
              onClick={() => {
                closeModal(true);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalConfirmation;
