import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useUploadImage from "../hooks/useUploadImage";
import logowib from "../assets/images/logo1.png";
import UserContext from "../services/UserContext";
import { useNavigate } from "react-router-dom";
import {
  addWineCardTab,
  getWineCardTab,
  getWineCardContent,
  addWineCardContentText,
  addWineCardContentImage,
  downloadWineCardContentImage,
  updateWineCardContentImage,
  updateWineCardContentText,
} from "../services/CardService";

const WineView = () => {
  const positionTab = 1;
  const tabName = "wine";
  const [currentUser] = useContext(UserContext);
  const [newCardObj, setNewCardObj] = useState();
  const [contentList, setContentList] = useState([]);
  const [file1, handleChange1] = useUploadImage();
  const [file2, handleChange2] = useUploadImage();
  const [mode, setMode] = useState("creation");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const newCardJSON = localStorage.getItem("newWineCard");

    if (newCardJSON) {
      setNewCardObj(JSON.parse(newCardJSON));
    }
  }, []);

  useEffect(() => {
    if (newCardObj) {
      fetchTabContent();
    }
  }, [newCardObj]);

  const fetchTabContent = async () => {
    let resultTab = await getWineCardTab(currentUser, newCardObj.id_wic_lang);

    const resultCurrentTab = resultTab.data.filter(
      (obj) => obj.position == positionTab
    );

    if (resultCurrentTab.length > 0) {
      setMode("edition");

      let resultContent = await getWineCardContent(
        currentUser,
        resultCurrentTab[0].id_wic_tab
      );

      getValuesForm(resultContent.data);

      setContentList(resultContent.data);
    }
  };

  const getValuesForm = (resultContent) => {
    for (let index = 0; index < resultContent.length; index++) {
      const value = resultContent[index];
      const position = value.position;

      if (value.hasOwnProperty("id_wic_content_image")) {
        displayImages(value);
      } else {
        setValue("pos" + position, value.content);
      }
    }
  };

  const displayImages = async (image) => {
    let url = await downloadWineCardContentImage(
      image.id_wic_content_image,
      currentUser
    );
    image.position === 4 ? handleChange1(url) : handleChange2(url);
  };

  const onSubmit = async (infos) => {
    if (!file1) {
      document.querySelector(`#error-image`).scrollIntoView();
      return;
    }
    newCardObj.position = positionTab; //position of the Tab Wine is 1

    let result;

    if (mode === "creation") {
      newCardObj.name = tabName;
      result = await addWineCardTab(newCardObj, currentUser);
      newCardObj.id_wic_tab = result.data.id;
    } else {
      result = await getWineCardTab(currentUser, newCardObj.id_wic_lang);
      newCardObj.id_wic_tab = result.data.filter(
        (obj) => obj.position == positionTab
      )[0].id_wic_tab;
    }

    let index = 0;
    for (const key in infos) {
      index++;
      const value = infos[key];

      // add Content Image
      if (value instanceof FileList) {
        newCardObj.file = value;
        newCardObj.position = index;

        const existingImage = contentList.filter(
          (obj) => obj.position === index
        );

        if (value.length > 0) {
          if (existingImage.length > 0) {
            newCardObj.id_wic_content_image =
              existingImage[0].id_wic_content_image;
            updateWineCardContentImage(newCardObj, currentUser);
          } else {
            addWineCardContentImage(newCardObj, currentUser);
          }
        }

        // add Content TExt
      } else {
        newCardObj.content = value;
        newCardObj.position = index;

        if (mode === "creation") {
          addWineCardContentText(newCardObj, currentUser);
        } else {
          newCardObj.id_wic_content_text = contentList.filter(
            (obj) => obj.position === index
          )[0].id_wic_content_text;

          updateWineCardContentText(newCardObj, currentUser);
        }
      }
    }
    navigate("./../");
  };

  const registerOptions = {
    pos1: { required: t('fieldRequired') },
    pos2: { required: t('fieldRequired') },
    pos3: { required: t('fieldRequired') },
    pos4: t('imageRequired'),
    pos5: { required: t('fieldRequired') },
    pos6: { required: t('fieldRequired') },
    pos7: { required: t('fieldRequired') },
    pos8: { required: t('fieldRequired') },
    pos9: { required: t('fieldRequired') },
    pos10: { required: t('fieldRequired') },
    pos11: { required: t('fieldRequired') },
    pos12: { required: t('fieldRequired') },
    pos13: { required: t('fieldRequired') },
    pos14: { required: t('fieldRequired') },
  };

  return (
    <div id="page">
      <form onSubmit={handleSubmit(onSubmit)} className="form-login">
        <div className="page-content">
          <div className="wine-top-infos">
            <h3 className="wine-name">
              <input
                type="text"
                className="input-text"
                placeholder={t("chateau")}
                {...register("pos1", registerOptions.pos1)}
              />
              <span className="error-msg">
                {errors?.pos1 && errors.pos1.message}
              </span>
            </h3>
            <div className="wine-infos-1">
              <input
                type="text"
                className="input-text"
                placeholder={t("aoc")}
                {...register("pos2", registerOptions.pos2)}
              />
              <span className="error-msg">
                {errors?.pos2 && errors.pos2.message}
              </span>
            </div>
            <div className="wine-infos-2">
              <input
                type="text"
                className="input-text"
                placeholder={t("wineinfo")}
                {...register("pos3", registerOptions.pos3)}
              />
              <span className="error-msg">
                {errors?.pos3 && errors.pos3.message}
              </span>
            </div>
          </div>

          <div className="content-wine-category">
            <div className="label-image">
              <div className="upload-image">
                <input
                  type="file"
                  id="file1"
                  {...register("pos4")}
                  onChange={handleChange1}
                  accept="image/jpeg, image/png"
                />
                <label htmlFor="file1">{t('chooseImage')}</label>
              </div>

              <img src={file1} />
              <span id="error-image" className="error-msg">
                {!file1 && registerOptions.pos4}
              </span>
            </div>

            <div className="identifier">
              <div className="caption-image">
                <img src={logowib} />
              </div>

              <div className="identifier-content">
                <h6 className="serial-number-label">
                  <input
                    type="text"
                    className="input-text"
                    placeholder={t("serial")}
                    {...register("pos5", registerOptions.pos5)}
                  />
                  <span className="error-msg">
                    {errors?.pos5 && errors.pos5.message}
                  </span>
                </h6>
                <span id="serial" className="serial-number-value">
                  1234564565
                </span>
                <h6 className="blockchain-label">
                  {" "}
                  <input
                    type="text"
                    className="input-text"
                    placeholder={t("blockchain")}
                    {...register("pos6", registerOptions.pos6)}
                  />
                  <span className="error-msg">
                    {errors?.pos6 && errors.pos6.message}
                  </span>
                </h6>
                <span id="blockchain" className="blockchain-value">
                  c016ab5e151f48b36dc3229d11389cc633504e9b6125a17195ba8aa2112611d1
                </span>
              </div>
            </div>
          </div>

          <div className="content-box">
            <h6>
              <input
                type="text"
                className="input-text"
                placeholder={t("grape")}
                {...register("pos7", registerOptions.pos7)}
              />
              <span className="error-msg">
                {errors?.pos7 && errors.pos7.message}
              </span>
            </h6>
            <p>
              {" "}
              <textarea
                className="textarea"
                placeholder={t("grapetext")}
                {...register("pos8", registerOptions.pos8)}
              />
              <span className="error-msg">
                {errors?.pos8 && errors.pos8.message}
              </span>
            </p>
          </div>
          <hr />

          <div className="content-box">
            <h6>
              <input
                type="text"
                className="input-text"
                placeholder={t("harvest")}
                {...register("pos9", registerOptions.pos9)}
              />
              <span className="error-msg">
                {errors?.pos9 && errors.pos9.message}
              </span>
            </h6>
            <p>
              {" "}
              <textarea
                className="textarea"
                placeholder={t("harvesttext")}
                {...register("pos10", registerOptions.pos10)}
              />
              <span className="error-msg">
                {errors?.pos10 && errors.pos10.message}
              </span>
            </p>
          </div>
          <hr />

          <div className="content-box">
            <h6>
              <input
                type="text"
                className="input-text"
                placeholder={t("climatology")}
                {...register("pos11", registerOptions.pos11)}
              />
              <span className="error-msg">
                {errors?.pos11 && errors.pos11.message}
              </span>
            </h6>
            <p>
              {" "}
              <textarea
                className="textarea"
                placeholder={t("climatologytext")}
                {...register("pos12", registerOptions.pos12)}
              />
              <span className="error-msg">
                {errors?.pos12 && errors.pos12.message}
              </span>
            </p>
          </div>
          <hr />

          <div className="content-box">
            <h6>
              <input
                type="text"
                className="input-text"
                placeholder={t("vinification")}
                {...register("pos13", registerOptions.pos13)}
              />
              <span className="error-msg">
                {errors?.pos13 && errors.pos13.message}
              </span>
            </h6>
            <p>
              <textarea
                className="textarea"
                placeholder={t("vinificationtext")}
                {...register("pos14", registerOptions.pos14)}
              />
              <span className="error-msg">
                {errors?.pos14 && errors.pos14.message}
              </span>
            </p>
          </div>

          <div className="content-image">
            <div className="upload-image">
              <input
                type="file"
                id="file2"
                {...register("pos15")}
                onChange={handleChange2}
                accept="image/jpeg, image/png"
              />
              <label htmlFor="file2">{t('chooseImage')}</label>
            </div>
            <img src={file2} />
          </div>
        </div>
        <div className="validate-section">
          <div
            className="cancel-bt cta-tertiary"
            onClick={() => navigate("./../")}
          >
            Cancel
          </div>
          <input
            className="validate-bt cta-primary"
            type="submit"
            value="Validate"
          />
        </div>
      </form>
    </div>
  );
};

export default WineView;
