import { useState } from "react";

const useUploadImage = () => {
  const [file, setFile] = useState(null);
  const MAX_FILE_SIZE = 5120; // 5MB

  const handleChange = (event) => {
    if (typeof event === "string") {
      setFile(event);
    } else {
      const selectedFile = event.target.files[0];
      const fileSizeKiloBytes = selectedFile.size / 1024;

      if (fileSizeKiloBytes < MAX_FILE_SIZE) {
        setFile(URL.createObjectURL(selectedFile));
      } else {
        alert("the image size is too large. 5mb max");
      }
    }
  };

  return [file, handleChange];
};

export default useUploadImage;
