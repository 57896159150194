import { getText, getImage, getDropdown, getTabName} from "./getContent"
import { headHtml } from "./head"
import { footerHtml } from "./footer"

export const documentsHtml = (tab, cardLangs,lang, tabsName) => {

  return  headHtml +`
  
  <div class="page-content">

  ${getDropdown(cardLangs,lang, 'documents')}

  <div class="content-top is-documents">
      <div class="wine-top-infos">
          <h3 class="wine-name">${getText(tab,1)}</h3>
          <div class="wine-infos-1">${getText(tab,2)}</div>
          <div class="wine-infos-2">${getText(tab,3)}</div>
      </div>
      <div class="caption-image">
          <img src="../../images/documents.png">
          <h5>${getText(tab,4)}</h5>
      </div>
  </div>


  <div class="content-box">
      <h6>${getText(tab,5)}</h6>
      <p> ${getText(tab,6)} </p>
  </div>

  <a class="bt-certificate" href="javascript:generateCertificate();">
      <img src="../../images/logo1.png">
      <span>${getText(tab,7)}</span></a>

</div>

<div class="footer-menu footer-5-icons footer-menu-center-icon text-center">
<a id="index" href="index.html">
    <i class="icon_accueil"></i><span>${getTabName(tabsName, 1)}</span>
</a>
<a id="propriete" href="propriete.html">
    <i class="icon_propriete"></i><span>${getTabName(tabsName, 2)}</span>
</a>
<a id="service" href="service.html">
    <i class="icon_services"></i><span>${getTabName(tabsName, 3)}</span>
</a>
<a id="tracabilite" href="tracabilite.html">
    <i class="icon_tracabilite"></i><span>${getTabName(tabsName, 4)}</span>
</a>
<a id="documents"  class="selected-item"  href="documents.html">
    <i class="icon_documents"></i><span>${getTabName(tabsName, 5)}</span>
</a>
</div>


`

+ footerHtml +

`
<script>

    $(document).ready(function () {

        const urlParams = new URLSearchParams(window.location.search);
        const serial = urlParams.get('s')
        console.log("Serial: " + serial);

        document.getElementById("index").href = "index.html?s=" + serial;
        document.getElementById("propriete").href = "propriete.html?s=" + serial;
        document.getElementById("service").href = "service.html?s=" + serial;
        document.getElementById("tracabilite").href = "tracabilite.html?s=" + serial;
        document.getElementById("documents").href = "documents.html?s=" + serial;

    });

    function generateCertificate() {
        console.log("Asking for Certificate");
        const urlParams = new URLSearchParams(window.location.search);
        const serial = urlParams.get('s')
        console.log("Serial: " + serial);

        var req = new XMLHttpRequest();
        req.open("GET", wibURL() + "/GenerateCertificate?serial=" + serial, true);
        req.responseType = "blob";
        req.onload = function (event) {
            var blob = req.response;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "CertificateWIB.pdf";
            link.click();
        }
        req.send();
    }

</script>




</html>`


};
