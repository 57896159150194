import React, { Component }  from 'react';
import "./styles.css";
import "./app-styles.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { UserProvider } from './services/UserContext';

import Dashboard from "./pages/dashboard";
import Login from "./components/Login";
import Header from "./components/Header";
import NewCardView from './pages/new-card';
import CardDetails from './pages/card-details';
import WineView from './components/WineView';
import MenuView from './components/MenuView';
import EstateView from './components/EstateView';
import ServiceView from './components/ServiceView';
import TraceabilityView from './components/TraceabilityView';
import DocumentsView from './components/DocumentsView';
import EditCardView from './pages/edit-card';


function App() {
  const { t } = useTranslation();
  return (
<>

<BrowserRouter>

      <UserProvider>
    
        
       
       <Header />
   
        <Routes>
          <Route path="/login" element={<Login />} /> 
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/" element={<Dashboard/>} />
          <Route path="/new-card" element={<NewCardView/>} />

          <Route path="/new-card/wine" element={<WineView />} />
          <Route path="/new-card/estate" element={<EstateView />} />
          <Route path="/new-card/service" element={<ServiceView />} />
          <Route path="/new-card/traceability" element={<TraceabilityView />} />
          <Route path="/new-card/documents" element={<DocumentsView />} />
          <Route path="/new-card/menu" element={<MenuView />} />

          
          <Route path="/card-details/:id" element={<CardDetails/>} />
          <Route path="/card-details/:id/edit/:id_flag" element={<EditCardView/>} />
          <Route path="/card-details/:id/edit/:id_flag/wine" element={<WineView />} />
          <Route path="/card-details/:id/edit/:id_flag/estate" element={<EstateView />} />
          <Route path="/card-details/:id/edit/:id_flag/service" element={<ServiceView />} />
          <Route path="/card-details/:id/edit/:id_flag/traceability" element={<TraceabilityView />} />
          <Route path="/card-details/:id/edit/:id_flag/documents" element={<DocumentsView />} />
          <Route path="/card-details/:id/edit/:id_flag/menu" element={<MenuView />} />

        </Routes>
   
      </UserProvider>
    </BrowserRouter>
</>

 
  );
}

export default App;
