import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { login } from "../services/AuthService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext from "../services/UserContext";

const Login = () => {

  console.log('publish server REACT_APP_PUBLISH_URL: '+process.env.REACT_APP_PUBLISH_URL)
  console.log('deployment: '+process.env.NODE_ENV)
  console.log('Wib server: '+process.env.REACT_APP_WIB_SERVER)


  const { t } = useTranslation();
  const [errorLogin, setErrorLogin] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleRegistration = (infos) => loginUser(infos);
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const navigate = useNavigate();

  const registerOptions = {
    login: { required: t("loginRequired") },
    password: { required: t("passwordRequired") },
  };

  const loginUser = async (infos) => {
    let result = await login(infos);
    if (result.message) {
      setErrorLogin(result.message);
    } else if (result.data.status === "failed") {
      setErrorLogin("bad login or password");
    } else if (result.data.status === "success") {
      setCurrentUser(result.data);
      navigate("/dashboard");
    }
  };

  return (
    <div className="app-login wrapper">
      <h1 className="title-creator">Winecard Creator</h1>
      <h1 className="title-h1">Login</h1>
      <form onSubmit={handleSubmit(handleRegistration)} className="form-login">
        <div className="section">
          <label>Login</label>
          <input
            name="login"
            type="text"
            className="input-text"
            {...register("login", registerOptions.login)}
          />
          <p className="error"> {errors?.login && errors.login.message}</p>
        </div>

        <div className="section">
          <label>Password</label>
          <input
            type="password"
            name="password"
            className="input-text"
            {...register("password", registerOptions.password)}
          />
          <p className="error">{errors?.password && errors.password.message}</p>
        </div>
        <button className="cta-primary"> {t("submit")}</button>
        <p className="error">{errorLogin}</p>
      </form>

      <p className="release">Release 00.04</p>
    </div>
  );
};

export default Login;
