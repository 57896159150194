import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  deleteWineCard,
  deleteWineCardLanguage,
  getWineCardLanguage,
  getWineCardTab,
  getWineCardContentImage,
  downloadWineCardContentImage,
  updateWineCard,
  getWineCard,
} from "../services/CardService";
import UserContext from "../services/UserContext";
import { cardLanguages } from "../utils/cardLanguages";
import addIcon from "../assets/images/add-card.png";
import bottleIcon from "../assets/images/bottle-wine.png";
import trashIcon from "../assets/images/trash.png";
import languageIcon from "../assets/images/new-language.png";
import ModalNewLanguage from "../components/ModalNewLanguage";
import BackButton from "../components/BackButton";
import ModalConfirmation from "../components/ModalConfirmation";
import { useTranslation } from "react-i18next";

const CardDetails = () => {
  const [currentUser] = useContext(UserContext);
  const [cardsWine, setCardsWine] = useState([]);
  const [cardName, setCardName] = useState([]);
  const [editingName, setEditingName] = useState(false);
  const [cardToDelete, setCardToDelete] = useState();
  const [toggleModalNewLanguage, setToggleModalNewLanguage] = useState(false);
  const [toggleModalDeleteCard, setToggleModalDeleteCard] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const registerOptions = {
    nameCard: { required: t("fieldRequired") },
  };

  useEffect(() => {
    const fetchWineCards = async () => {
      let result = await getWineCardLanguage(currentUser, id);

      const cards = result.data;
      const promises = [];

      for (let i = 0; i < cards.length; i++) {
        promises.push(getImageCards(cards[i].id_wic_lang));
      }
      Promise.all(promises).then((res) => {
        const newCards = cards.map((obj, index) => {
          obj.url_image = res[index];
          return obj;
        });
        setCardsWine(newCards);
      });
    };
    const getTitleCard = async () => {
      let result = await getWineCard(currentUser);
      const title = result.data.filter((obj) => obj.id_wic == id)[0].name;

      setCardName(title);
      setValue("nameCard", title);
    };

    const getImageCards = async (id_wic_lang) => {
      const resultTab = await getWineCardTab(currentUser, id_wic_lang);

      if (resultTab.data.length > 0) {
        const firstTab = resultTab.data.filter((obj) => obj.position === 1);
        let id_wic_tab;

        if (firstTab.length > 0) {
          id_wic_tab = resultTab.data.filter((obj) => obj.position === 1)[0]
            .id_wic_tab;
        } else {
          return bottleIcon;
        }

        const resultImages = await getWineCardContentImage(
          currentUser,
          id_wic_tab
        );
        const id_wic_content_image = resultImages.data.filter(
          (obj) => obj.position === 4
        )[0].id_wic_content_image;
        const urlImage = downloadWineCardContentImage(
          id_wic_content_image,
          currentUser
        );
        return urlImage;
      }
      return bottleIcon;
    };

    if (currentUser) {
      fetchWineCards();
      getTitleCard();
    }
  }, [currentUser]);

  const getLanguage = (type, codeFlag) => {
    const langObj = cardLanguages.filter((lang) => lang.flag === codeFlag)[0];

    if (type === "name") {
      return langObj.name;
    } else {
      return langObj.flag;
    }
  };

  const handleModalNewLanguage = () => {
    setToggleModalNewLanguage(!toggleModalNewLanguage);
  };

  const closeModalDeleteCard = (toDelete) => {
    if (toDelete) {
      const filteredData = cardsWine.filter(
        (item) => item.id_wic_lang !== cardToDelete
      );

      if (filteredData.length > 0) {
        setCardsWine(filteredData);
        deleteWineCardLanguage(currentUser, cardToDelete);
      } else {
        deleteWineCard(currentUser, cardsWine[0].id_wic);
        navigate("/dashboard");
        navigate(0);
      }
    }
    setToggleModalDeleteCard(false);
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (infos) => {
    console.log('infos', infos)
    const objCard = {}
     objCard.name = infos.nameCard;
     objCard.id_wic = id;
    await updateWineCard(objCard, currentUser);
    setEditingName(false)



  };

  const handleDeleteCard = (cardId) => {
    setCardToDelete(cardId);
    setToggleModalDeleteCard(true);
  };

  return (
    <div className="app-dashboard wrapper is-details">
      {toggleModalNewLanguage && (
        <ModalNewLanguage
          cardsWine={cardsWine}
          closeModal={handleModalNewLanguage}
        />
      )}
      {toggleModalDeleteCard && (
        <ModalConfirmation
          infoText={t("confirmLanguageDelete")}
          closeModal={closeModalDeleteCard}
        />
      )}
      <BackButton url="/dashboard" />

      <form className={"form-name" + (editingName ? " edit" : '' )} onSubmit={handleSubmit(onSubmit)}>
        {cardName && (
   
            <input
              type="text"
              className="input-text"
              {...register("nameCard", registerOptions.nameCard)}
            />
      
        )}
        {!editingName &&
          <button onClick={() => setEditingName(true)}className="cta-primary">
          change Name
        </button>
        }
      

        {editingName &&   <button type="submit" className="cta-primary">
         {t("validate")}
        </button>}
      </form>
      {errors?.nameCard && <p className="error-msg"> {errors.nameCard.message}</p>}


      <div className="cards-wrapper">
        <div
          className="wine-card add-language-card"
          onClick={() => handleModalNewLanguage()}
        >
          <img src={languageIcon} />
          <span>{t("addNewLanguage")}</span>
        </div>
        {cardsWine.length > 0 ? (
          cardsWine.map((card, index) => {
            return (
              <div key={index} className="wine-card">
                <div className="wine-card-infos">
                  <div className="card-lang">
                    <span
                      className={"flag " + getLanguage("flag", card.url_flag)}
                    ></span>
                    {getLanguage("name", card.url_flag)}
                  </div>

                  <div
                    className="thumbnail"
                    style={{
                      marginBottom: "10px",
                      backgroundImage: `url(${card.url_image})`,
                    }}
                  >
                    <div
                      onClick={() => {
                        handleDeleteCard(card.id_wic_lang);
                      }}
                      className="delete-bt"
                    >
                      <img src={trashIcon} />{" "}
                    </div>
                  </div>
                </div>

                <div className="cta-section">
                  <div
                    onClick={() => {
                      navigate(`./edit/${card.url_flag}`);
                    }}
                    className="cta-small-secondary details"
                  >
                    {t("editBt")}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p> {t("noCards")}</p>
        )}
      </div>
    </div>
  );
};

export default CardDetails;
