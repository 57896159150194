import React, { useEffect, useContext, useState } from "react";
import { cardLanguages } from "../utils/cardLanguages";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserContext from "../services/UserContext";
import wineIcon from "../assets/images/ico_accueil__.png";
import estateIcon from "../assets/images/propriete.png";
import servingIcon from "../assets/images/services.png";
import traceabilityIcon from "../assets/images/tracabilite.png";
import documentsIcon from "../assets/images/documents.png";
import {
  getWineCard,
  getWineCardTab,
  getWineCardLanguage,
  updateWineCard,
} from "../services/CardService";

const EditCardView = () => {
  const navigate = useNavigate();
  const [currentUser] = useContext(UserContext);
  const [newCardObj, setNewCardObj] = useState();
  const [cardName, setCardName] = useState();
  const [tabsCompleted, setTabsCompleted] = useState();
  const [tabsNameCompleted, setTabsNameCompleted] = useState(false);
  const { id } = useParams();
  const { id_flag } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCurrentCard = async () => {
      let result = await getWineCardLanguage(currentUser, id);
    

      const currentTab = result.data.filter(
        (obj) => obj.url_flag === id_flag
      )[0];
      const language = cardLanguages.filter((lang) => lang.flag === id_flag)[0]
        .name;
      currentTab.language = language;
      currentTab.flag = currentTab.url_flag;

      localStorage.setItem("newWineCard", JSON.stringify(currentTab));

      setNewCardObj(currentTab);
    };
    if (currentUser) fetchCurrentCard();
  }, [currentUser]);

  useEffect(() => {
    const fetchTab = async () => {
    
      let result = await getWineCardTab(currentUser, newCardObj.id_wic_lang);
      setTabsCompleted(result.data);
      let resultLang = await getWineCardLanguage(currentUser, newCardObj.id_wic);

      const currentLang = resultLang.data.filter(obj => obj.id_wic_lang == newCardObj.id_wic_lang)[0]
      const tabsName = currentLang.tabsname
      if(tabsName !== "")  setTabsNameCompleted(true)
    };

    if (newCardObj) {
      fetchTab();
      getTitleCard();
    }
  }, [newCardObj]);



  const getLanguage = (type) => {
    if (type === "name") {
      return newCardObj.language;
    } else {
      const langObj = cardLanguages.filter(
        (lang) => lang.name === newCardObj.language
      )[0];
      return langObj.flag;
    }
  };

  const getTitleCard = async () => {
      let result = await getWineCard(currentUser);
      const title = result.data.filter((obj) => obj.id_wic == id)[0].name;

      setCardName(title);
    };

  const getTabCompleted = (position) => {
    if (tabsCompleted) {
      const tab = tabsCompleted.filter((obj) => obj.position == position);

      return tab.length > 0 ? "is-valid" : "";
    }
  };
  


  return (
    <>
      <div className="app-card wrapper is-edit">
        <h1 className="title-h1"> {t("editWineCard")}</h1>

          <div className="language-section">
            {newCardObj && (
              <h3 className="title-h3">
                {t("Cardlanguage")} :
                <span className="lang">
                  <span className={"flag " + id_flag}></span>
                  {getLanguage("name")}
                </span>
              </h3>
            )}
          </div>

          <div className="name-section">
            <h3 className="title-h3"> {t("myCardName")} : </h3>
            
           {cardName && <h2>{cardName}</h2> }
          </div>
     

          <h3 className="title-categories title-h3">
            {t("fillCategoriesTxt")}
          </h3>

          <ul className="categories">
            <li
              onClick={() => navigate("./wine")}
              className={getTabCompleted(1)}
            >
              <img src={wineIcon} /> {t("tabWine")}{" "}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./estate")}
              className={getTabCompleted(2)}
            >
              <img src={estateIcon} /> {t("tabEstate")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./service")}
              className={getTabCompleted(3)}
            >
              <img src={servingIcon} />
              {t("tabService")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./Traceability")}
              className={getTabCompleted(4)}
            >
              <img src={traceabilityIcon} />
              {t("tabTraceability")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./documents")}
              className={getTabCompleted(5)}
            >
              <img src={documentsIcon} />
              {t("tabDocuments")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./menu")}
              className={"menu " + (tabsNameCompleted ? "is-valid" : "")}
            >
              {t('menuTabs')}
              <span className="checkmark"></span>
            </li>
          </ul>

          <div className="section-cta">
            <div
              className="cancel-bt cta-tertiary"
              onClick={() => navigate(`/card-details/${id}`)}
            >
              {t("cancel")}
            </div>
            <input
              className="create-bt cta-primary"
              type="submit"
              value={t("saveCard")}
              onClick={() => navigate(`/card-details/${id}`)}
            />
          </div>
       
      </div>
    </>
  );
};

export default EditCardView;
