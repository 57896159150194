import React, { useState, useContext, useEffect } from "react";
import Lang from "./Lang";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserContext from "../services/UserContext";
import logo from "../assets/images/wib.png";
import logout from "../assets/images/logout.png";
import ModalConfirmation from "./ModalConfirmation";
import axios from "axios";
import { useTranslation } from "react-i18next";


const Header = () => {
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const [sessionTimetout, setSessionTimeout] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response?.data?.message === "ERROR_103") {
          navigate("/login");
          setSessionTimeout(true);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  const [toggleModalLogout, setToggleModalLogout] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    setToggleModalLogout(true);
  };

  const closeModalLogout = (confirm) => {
    setToggleModalLogout(false);
    if (confirm) {
      localStorage.removeItem("user");
      setCurrentUser(null);
      navigate("/login");
    }
  };

  const closeModalTimeout = () => {
    setSessionTimeout(false);
  };

  return (
    <div className="app-header">
      {toggleModalLogout && (
        <ModalConfirmation
          infoText={t('confirmLogout')}
          closeModal={closeModalLogout}
        />
      )}
      {sessionTimetout && (
        <ModalConfirmation
          infoText={t('sessionTimeout')}
          closeModal={closeModalTimeout}
          noValidation={true}
          customClass="timeout"
        />
      )}
      <img
        className="logo"
        src={logo}
        onClick={() => {
          navigate("/dashboard");
        }}
      />

      <div className="right-section">
        <Lang />
        {currentUser && (
          <img
            className="logout"
            src={logout}
            onClick={() => {
              handleLogout();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
