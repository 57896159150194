import { getText, getImage, getMainImage, getDropdown, getTabName} from "./getContent"
import { headHtml } from "./head"
import { footerHtml } from "./footer"

export const wineHtml = (tab, cardLangs,lang, tabsName) => {


  return  headHtml +` <div class="page-content">


  ${getDropdown(cardLangs,lang, 'index')}


<div class="wine-top-infos">
    <h3 class="wine-name">${getText(tab,1)}</h3>
    <div class="wine-infos-1">${getText(tab,2)}</div>
    <div class="wine-infos-2">${getText(tab,3)}</div>
</div>

<div class="content-wine-category">
    <div class="label-image">
       ${getMainImage(tab,4)}
    </div>

    <div class="identifier">
        <div class="caption-image">
            <img src="../../images/logo1.png">
        </div>

        <div class="identifier-content">
            <h7 class="serial-number-label">${getText(tab,5)}</h7>
            <span id="serial" class="serial-number-value">1234564565</span>
            <h7 class="blockchain-label">${getText(tab, 6)}</h7>
            <span id="blockchain"
                class="blockchain-value">c016ab5e151f48b36dc3229d11389cc633504e9b6125a17195ba8aa2112611d1</span>
        </div>
    </div>
</div>

<div class="content-box">
    <h6>${getText(tab,7)}</h6>
    <p>${getText(tab,8)}</p>
</div>
<hr>

<div class="content-box">
    <h6>${getText(tab,9)}</h6>
    <p>${getText(tab,10)}</p>
</div>
<hr>

<div class="content-box">
    <h6>${getText(tab,11)}</h6>

    <p>${getText(tab,12)}</p>
</div>
<hr>

<div class="content-box">

    <h6>${getText(tab,13)}</h6>

    <p>${getText(tab,14)}</p>

</div>

<div class="content-image">
    ${getImage(tab,15)}
</div>

<div class="menu-hider"></div>
</div>

<div class="footer-menu footer-5-icons footer-menu-center-icon text-center">
<a id="index" class="selected-item"  href="index.html">
    <i class="icon_accueil"></i><span>${getTabName(tabsName, 1)}</span>
</a>
<a id="propriete" href="propriete.html">
    <i class="icon_propriete"></i><span>${getTabName(tabsName, 2)}</span>
</a>
<a id="service" href="service.html">
    <i class="icon_services"></i><span>${getTabName(tabsName, 3)}</span>
</a>
<a id="tracabilite" href="tracabilite.html">
    <i class="icon_tracabilite"></i><span>${getTabName(tabsName, 4)}</span>
</a>
<a id="documents" href="documents.html">
    <i class="icon_documents"></i><span>${getTabName(tabsName, 5)}</span>
</a>
</div>


`

+ footerHtml +

`<script>

$(document).ready(function () {


    const urlParams = new URLSearchParams(window.location.search);
    const serial = urlParams.get('s')
    console.log("Serial: " + serial);

    document.getElementById("index").href = "index.html?s=" + serial;
    document.getElementById("propriete").href = "propriete.html?s=" + serial;
    document.getElementById("service").href = "service.html?s=" + serial;
    document.getElementById("tracabilite").href = "tracabilite.html?s=" + serial;
    document.getElementById("documents").href = "documents.html?s=" + serial;

    $.ajax({
        url: wibURL() + '/GetTagInfo?s=' + serial,
        type: 'GET',
        processData: false,
        contentType: false,
        async: true,
        success: function (responseJson) {
            console.log("Response: " + responseJson.content[0].serial);
            console.log("serial: " + responseJson.content[0].serial);

            if (responseJson.status == "failed") {
                console.log("Failed data: " + responseJson.message);
                document.getElementById("serial").innerHTML = " ";
                document.getElementById("blockchain").innerHTML = " \\n";
                return;
            }
            else {
                console.log("serial: " + responseJson.id);
                console.log("blockchain: " + responseJson.content[0].id_blockchain);
                document.getElementById("serial").innerHTML = responseJson.content[0].serial;
                document.getElementById("blockchain").innerHTML = responseJson.content[0].id_blockchain;
            }
        }
    });
});

</script>




</html>`


};
