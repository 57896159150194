import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useUploadImage from "../hooks/useUploadImage";
import UserContext from "../services/UserContext";
import topIcon from "../assets/images/services.png";
import carafage from "../assets/images/carafage.png";
import temperature from "../assets/images/temperature.png";
import garde from "../assets/images/garde.png";
import { useNavigate } from "react-router-dom";
import {
  addWineCardTab,
  getWineCardTab,
  getWineCardContent,
  addWineCardContentText,
  addWineCardContentImage,
  downloadWineCardContentImage,
  updateWineCardContentImage,
  updateWineCardContentText,
} from "../services/CardService";

const ServiceView = () => {
  const positionTab = 3;
  const tabName = "service";
  const [currentUser] = useContext(UserContext);
  const [newCardObj, setNewCardObj] = useState();
  const [contentList, setContentList] = useState([]);
  const [file1, handleChange1] = useUploadImage();
  const [mode, setMode] = useState("creation");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const newCardJSON = localStorage.getItem("newWineCard");

    if (newCardJSON) {
      setNewCardObj(JSON.parse(newCardJSON));
    }
  }, []);

  useEffect(() => {
    if (newCardObj) {
      fetchTabContent();
    }
  }, [newCardObj]);

  const fetchTabContent = async () => {
    let resultTab = await getWineCardTab(currentUser, newCardObj.id_wic_lang);

    const resultCurrentTab = resultTab.data.filter(
      (obj) => obj.position == positionTab
    );

    if (resultCurrentTab.length > 0) {
      setMode("edition");

      let resultContent = await getWineCardContent(
        currentUser,
        resultCurrentTab[0].id_wic_tab
      );

      getValuesForm(resultContent.data);

      setContentList(resultContent.data);
    }else{
      setWineValues(resultTab)
    }
    
  };

  const getValuesForm = (resultContent) => {
    for (let index = 0; index < resultContent.length; index++) {
      const value = resultContent[index];
      const position = value.position;

      if (value.hasOwnProperty("id_wic_content_image")) {
        displayImages(value);
      } else {
        setValue("pos" + position, value.content);
      }
    }
  };

  const setWineValues = async (resultTab) => {

    const firstTab = resultTab.data.filter((obj) => obj.position === 1);
 
    if (firstTab.length > 0) {
      let resultContent = await getWineCardContent(
        currentUser,
        firstTab[0].id_wic_tab
      );
      setValue("pos1", resultContent.data.filter(obj => obj.position === 1)[0].content);
      setValue("pos2", resultContent.data.filter(obj => obj.position === 2)[0].content);
      setValue("pos3", resultContent.data.filter(obj => obj.position === 3)[0].content);

    }
  };

  const displayImages = async (image) => {
    let url = await downloadWineCardContentImage(
      image.id_wic_content_image,
      currentUser
    );
    handleChange1(url);
  };

  const onSubmit = async (infos) => {
    newCardObj.position = positionTab; //position of the Tab Wine is 1

    let result;

    if (mode === "creation") {
      newCardObj.name = tabName;
      result = await addWineCardTab(newCardObj, currentUser);
      newCardObj.id_wic_tab = result.data.id;
    } else {
      result = await getWineCardTab(currentUser, newCardObj.id_wic_lang);
      newCardObj.id_wic_tab = result.data.filter(
        (obj) => obj.position == positionTab
      )[0].id_wic_tab;
    }

    let index = 0;
    for (const key in infos) {
      index++;
      const value = infos[key];

      // add Content Image
      if (value instanceof FileList) {
        newCardObj.file = value;
        newCardObj.position = index;

        const existingImage = contentList.filter(
          (obj) => obj.position === index
        );

        if (value.length > 0) {
          if (existingImage.length > 0) {
            newCardObj.id_wic_content_image =
              existingImage[0].id_wic_content_image;
            updateWineCardContentImage(newCardObj, currentUser);
          } else {
            addWineCardContentImage(newCardObj, currentUser);
          }
        }

        // add Content TExt
      } else {
        newCardObj.content = value;
        newCardObj.position = index;

        if (mode === "creation") {
          addWineCardContentText(newCardObj, currentUser);
        } else {
          newCardObj.id_wic_content_text = contentList.filter(
            (obj) => obj.position === index
          )[0].id_wic_content_text;

          updateWineCardContentText(newCardObj, currentUser);
        }
      }
    }
    navigate("./../");
  };

  const registerOptions = {
    pos1: { required: t('fieldRequired') },
    pos2: { required: t('fieldRequired') },
    pos3: { required: t('fieldRequired') },
    pos4: { required: t('fieldRequired') },
    pos5: { required: t('fieldRequired') },
    pos6: { required: t('fieldRequired') },
    pos7: { required: t('fieldRequired') },
    pos8: { required: t('fieldRequired') },
    pos9: { required: t('fieldRequired') },
    pos10: { required: t('fieldRequired') },
    pos11: { required: t('fieldRequired') },
    pos12: { required: t('fieldRequired') },
    pos13: { required: t('fieldRequired') },
    pos14: { required: t('fieldRequired') },
  };

  return (
    <div id="page">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-content">
          <div className="content-top">
            <div className="wine-top-infos">
              <h3 className="wine-name">
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("chateau")}
                  {...register("pos1", registerOptions.pos1)}
                />
                <span className="error-msg">
                  {errors?.pos1 && errors.pos1.message}
                </span>
              </h3>
              <div className="wine-infos-1">
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("aoc")}
                  {...register("pos2", registerOptions.pos2)}
                />
                <span className="error-msg">
                  {errors?.pos2 && errors.pos2.message}
                </span>
              </div>
              <div className="wine-infos-2">
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("wineinfo")}
                  {...register("pos3", registerOptions.pos3)}
                />
                <span className="error-msg">
                  {errors?.pos3 && errors.pos3.message}
                </span>
              </div>
            </div>
            <div className="caption-image">
              <img src={topIcon} />
              <h5>
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("services")}
                  {...register("pos4", registerOptions.pos4)}
                />
                <span className="error-msg">
                  {errors?.pos4 && errors.pos4.message}
                </span>
              </h5>
            </div>
          </div>

          <div className="content-box">
            <h6>
              <input
                type="text"
                className="input-text"
                placeholder={t("tasting")}
                {...register("pos5", registerOptions.pos5)}
              />
              <span className="error-msg">
                {errors?.pos5 && errors.pos5.message}
              </span>
            </h6>
            <p>
              {" "}
              <textarea
                className="textarea"
                placeholder={t("tastingtext")}
                {...register("pos6", registerOptions.pos6)}
              />
              <span className="error-msg">
                {errors?.pos6 && errors.pos6.message}
              </span>
            </p>
          </div>

          <div className="tasting-box">
            <img src={garde} />
            <div className="tasting-infos">
              <h5>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("storage")}
                  {...register("pos7", registerOptions.pos7)}
                />
                <span className="error-msg">
                  {errors?.pos7 && errors.pos7.message}
                </span>
              </h5>
              <p>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("storagetext")}
                  {...register("pos8", registerOptions.pos8)}
                />
                <span className="error-msg">
                  {errors?.pos8 && errors.pos8.message}
                </span>
              </p>
            </div>
          </div>

          <div className="tasting-box">
            <img src={temperature} />
            <div className="tasting-infos">
              <h5>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("temperature")}
                  {...register("pos9", registerOptions.pos9)}
                />
                <span className="error-msg">
                  {errors?.pos9 && errors.pos9.message}
                </span>
              </h5>
              <p>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("temperaturetext")}
                  {...register("pos10", registerOptions.pos10)}
                />
                <span className="error-msg">
                  {errors?.pos10 && errors.pos10.message}
                </span>
              </p>
            </div>
          </div>

          <div className="tasting-box">
            <img src={carafage} />
            <div className="tasting-infos">
              <h5>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("decanting")}
                  {...register("pos11", registerOptions.pos11)}
                />
                <span className="error-msg">
                  {errors?.pos11 && errors.pos11.message}
                </span>
              </h5>
              <p>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("decantingtext")}
                  {...register("pos12", registerOptions.pos12)}
                />
                <span className="error-msg">
                  {errors?.pos12 && errors.pos12.message}
                </span>
              </p>
            </div>
          </div>

          <div className="tasting-box">
            <img src={temperature} />
            <div className="tasting-infos">
              <h5>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("pairing")}
                  {...register("pos13", registerOptions.pos13)}
                />
                <span className="error-msg">
                  {errors?.pos13 && errors.pos13.message}
                </span>
              </h5>
              <p>
                {" "}
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("pairingtext")}
                  {...register("pos14", registerOptions.pos14)}
                />
                <span className="error-msg">
                  {errors?.pos14 && errors.pos14.message}
                </span>
              </p>
            </div>
          </div>

          <div className="content-image">
            <div className="upload-image">
              <input
                type="file"
                id="file1"
                {...register("pos15")}
                onChange={handleChange1}
                accept="image/jpeg, image/png"
              />
              <label htmlFor="file1">{t('chooseImage')}</label>
            </div>

            <img src={file1} />
          </div>
        </div>

        <div className="validate-section">
          <div
            className="cancel-bt cta-tertiary"
            onClick={() => navigate("./../")}
          >
            Cancel
          </div>
          <input
            className="validate-bt cta-primary"
            type="submit"
            value="Validate"
          />
        </div>
      </form>
    </div>
  );
};

export default ServiceView;
