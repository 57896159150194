import React, { useState, useEffect, createContext } from 'react';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from './AuthService';
import { useNavigate } from 'react-router-dom';

import Login from '../components/Login';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [ currentUser, setCurrentUser ] = useState(undefined);
    const navigate = useNavigate();

	useEffect(() => {
		const checkLoggedIn = async () => {

            const cuser = isAuthenticated();
            if (cuser === null) {
                setCurrentUser(null);
                navigate('/login')
			}else{
                setCurrentUser(cuser);
            }

		};

		checkLoggedIn();
	}, []);


	return (
		<UserContext.Provider value={[currentUser, setCurrentUser]}>
			{/* { currentUser?.session ? children : <Login />} */}
            {children}
		</UserContext.Provider>
	);
};


export default UserContext;