import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { login } from "../services/AuthService";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../services/UserContext";
import { cardLanguages } from "../utils/cardLanguages";
import { addWineCard, addWineCardLanguage } from "../services/CardService";

const ModalNewLanguage = ({ cardsWine, closeModal }) => {
  useEffect(() => {
    const languagesAvailables = cardLanguages.filter((language) => {
      return !cardsWine.some((card) => card.url_flag === language.flag);
    });
    setLanguages(languagesAvailables);
  }, []);

  const { t } = useTranslation();
  const { id } = useParams();
  const [currentUser] = useContext(UserContext);
  const [languages, setLanguages] = useState();
  const navigate = useNavigate();

  const getFlag = (name) => {
    return cardLanguages.filter((language) => language.name === name)[0].flag;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (infos) => {
    const newCardObj = {};
    newCardObj.id_wic = id;
    newCardObj.name = cardsWine[0].name;
    newCardObj.flag = getFlag(infos.language);
    newCardObj.language = infos.language;

    let resultLang = await addWineCardLanguage(newCardObj, currentUser);

    if (resultLang?.data) {
      newCardObj.id_wic_lang = resultLang.data.id;
      localStorage.setItem("newWineCard", JSON.stringify(newCardObj));
      navigate(`./edit/${newCardObj.flag}`);
    }
  };

  return (
    <div className="modal-bg ">
      <div className="modal new-language">
        <div
          className="close-modal"
          onClick={() => {
            closeModal();
          }}
        ></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="title-h1">{t("addNewLanguage")}</h1>
          <div className="language-section">
            <h3 className="title-h3">{t("chooseCardLanguage")}</h3>

            {languages && (
              <select {...register("language")}>
                {languages.map((language, index) => (
                  <option key={index} value={language.name}>
                    {language.name}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className="section-cta">
            <div
              className="cancel-bt cta-tertiary"
              onClick={() => {
                closeModal();
              }}
            >
              {t("cancel")}
            </div>
            <input
              className="create-bt cta-primary"
              type="submit"
              value={t("createCard")}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalNewLanguage;
