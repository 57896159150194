import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  deleteWineCard,
  getWineCard,
  getWineCardTab,
  getWineCardContentImage,
  getWineCardLanguage,
  downloadWineCardContentImage,
} from "../services/CardService";
import { publishCard, createZip, sendEmail } from "../services/PublishService";
import UserContext from "../services/UserContext";
import addIcon from "../assets/images/add-card.png";
import bottleIcon from "../assets/images/bottle-wine.png";
import trashIcon from "../assets/images/trash.png";
import ModalNewCard from "../components/ModalNewCard";
import ModalConfirmation from "../components/ModalConfirmation";
import { cardLanguages } from "../utils/cardLanguages";
import { useTranslation } from "react-i18next";



const Dashboard = () => {
  const [currentUser] = useContext(UserContext);
  const [cardsWine, setCardsWine] = useState([]);
  const [cardToDelete, setCardToDelete] = useState();
  const [cardToPublish, setCardToPublish] = useState();
  const [toggleModalNewCard, setToggleModalNewCard] = useState(false);
  const [toggleModalDeleteCard, setToggleModalDeleteCard] = useState(false);
  const [toggleModalPublish, setToggleModalPublish] = useState(false);
  const [toggleModalPublished, setToggleModalPublished] = useState(false);
  const [toggleModalPreview, setToggleModalPreview] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {

    // console.log('publish server REACT_APP_PUBLISH_URL: '+process.env.REACT_APP_PUBLISH_URL)
    // console.log('deployment: '+process.env.NODE_ENV)
    // console.log('Wib server: '+process.env.REACT_APP_WIB_SERVER)

    const fetchWineCards = async () => {
      let result = await getWineCard(currentUser);

      if (result.data) {
        const promisesTree = [];
        const treeInfos = result.data;
        // console.log("result data "+result.data)
        // console.log('resultData: '+Object.keys(result.data))
        for (let i = 0; i < treeInfos.length; i++) {
          promisesTree.push(getCardsInfos(treeInfos[i].id_wic));
        }

        Promise.all(promisesTree).then((res) => {
          // console.log("leng "+treeInfos.length)
          for (let i = 0; i < treeInfos.length; i++) {
            // console.log("url image: "+treeInfos[i].urlImage)
            // console.log('obj: '+Object.keys(res[i]))
            if(res[i].length > 0){
              // console.log("res image: "+res[i][0].imageCard)
                treeInfos[i].url_image = treeInfos[i].url_image
                ? bottleIcon
                : res[i][0].imageCard;
              treeInfos[i].lang = [];
            }              
            for (let j = 0; j < res[i].length; j++) {
              treeInfos[i].lang.push(res[i][j].treeLang);
            }

          }
          setCardsWine(treeInfos);
        });
      }
    };

    if (currentUser) {
      fetchWineCards();
    }
  }, [currentUser]);

  const getCardDetail = async (treeLang) => {
    const resultTab = await getWineCardTab(currentUser, treeLang.id_wic_lang);

    // console.log('resultTab: '+Object.keys(resultTab))
    // console.log('resultTabData: '+Object.keys(resultTab.data))
    
    let imageCard;

    if (resultTab.data.length > 0) {
      const firstTab = resultTab.data.filter((obj) => obj.position === 1);

      if (firstTab.length > 0) {
        let id_wic_tab = resultTab.data.filter((obj) => obj.position === 1)[0]
          .id_wic_tab;

        const resultImages = await getWineCardContentImage(
          currentUser,
          id_wic_tab
        );

        // console.log('resultImages: '+Object.keys(resultImages.data))

        const id_wic_content_image = resultImages.data.filter(
          (obj) => obj.position === 4
        )[0].id_wic_content_image;

        const urlImage = await downloadWineCardContentImage(
          id_wic_content_image,
          currentUser
        );
        imageCard = urlImage;
      } else {
        imageCard = bottleIcon;
      }
    } else {
      imageCard = bottleIcon;
    }

    treeLang.tabs = resultTab.data;

    return { treeLang, imageCard };
  };

  const getCardsInfos = async (id_wic) => {
    const result = await getWineCardLanguage(currentUser, id_wic);
    const promisesTreeLang = [];
    const treeLang = result.data;

    for (let i = 0; i < treeLang.length; i++) {
      promisesTreeLang.push(getCardDetail(treeLang[i]));
    }

    return Promise.all(promisesTreeLang).then((res) => {
      return res;
    });
  };

  const handleModalNewCard = () => {
    setToggleModalNewCard(!toggleModalNewCard);
  };
  const closeModalDeleteCard = (toDelete) => {
    if (toDelete) {
      const filteredData = cardsWine.filter(
        (item) => item.id_wic !== cardToDelete
      );

      setCardsWine(filteredData);
      deleteWineCard(currentUser, cardToDelete);
    }
    setToggleModalDeleteCard(false);
  };

  const handleDeleteCard = (cardId) => {
    setCardToDelete(cardId);
    setToggleModalDeleteCard(true);
  };

  const closeModalPublish = async (confirm) => {
    const card = cardsWine.filter((obj) => obj.id_wic === cardToPublish)[0];

    if (confirm) {
      setToggleModalPublish(false);
      let res = await publishCard(card, currentUser);
      setToggleModalPublished({status: res.status });
      if(res.status === 'success'){
        createZip(cardToPublish);
        sendEmail(cardToPublish) 
      }

    }else{
      setToggleModalPublish(false)
    }
  
  };

  const handleModalPublish = (cardId) => {
    setCardToPublish(cardId, currentUser);
    setToggleModalPublish(true);
  };

  const closeModalPreview = async (confirm) => {
    const cardId = cardToPublish;
    if (confirm) {
      const card = cardsWine.filter((obj) => obj.id_wic === cardId)[0];

      const urlFlag = card.lang[0].url_flag;
      const lang = cardLanguages.filter((obj) => obj.flag == urlFlag)[0].code;

      await publishCard(card, currentUser);
      const path = process.env.REACT_APP_WIB_PREVIEW+`/${cardId}/${lang}/index.html`;
      console.log('path', path)
      window.open(path, '_blank', 'width=500,height=900');
    }
    setToggleModalPreview(false);
  };

  const handleModalPreview = (cardId) => {
    setCardToPublish(cardId, currentUser);
    setToggleModalPreview(true);
  };
  const closeModalPublished = () => {
    setToggleModalPublished(false);
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue
  } = useForm();

  const registerOptions = {
    name: { required: t('fieldRequired') },
  };

  return (
    <div className="app-dashboard wrapper">
      {toggleModalNewCard && <ModalNewCard closeModal={handleModalNewCard} />}
      {toggleModalPublish && (
        <ModalConfirmation
          infoText={t("confirmPublish")}
          closeModal={closeModalPublish}
        />
      )}
      {toggleModalPreview && (
        <ModalConfirmation
          infoText={t("confirmPreview")}
          closeModal={closeModalPreview}
        />
      )}
      {toggleModalDeleteCard && (
        <ModalConfirmation
          infoText={t("confirmDelete")}
          currentUser={currentUser}
          closeModal={closeModalDeleteCard}
        />
      )}
      {toggleModalPublished && (
        <ModalConfirmation
          infoText={ toggleModalPublished.status === 'success' ? t("publishSuccess") : t("publishFailed")}
          currentUser={currentUser}
          closeModal={closeModalPublished}
          noValidation= {true}
        />
      )}
      <a
        className="bt-create cta-secondary"
        onClick={() => handleModalNewCard()}
      >
        <img src={addIcon} />

        {t("newWineCard")}
      </a>
      <h2 className="title-h2"> {t("myWineCards")}</h2>

      <div className="cards-wrapper">
        {cardsWine.length > 0 ? (
          cardsWine.map((card) => {
            return (
              <div key={card.id_wic} className="wine-card">
                <div className="wine-card-infos">
                  <div
                    className="thumbnail"
                    style={{ backgroundImage: `url(${card.url_image})` }}
                  >
                    <div
                      onClick={() => {
                        handleDeleteCard(card.id_wic);
                      }}
                      className="delete-bt"
                    >
                      <img src={trashIcon} />{" "}
                    </div>
                  </div>

                  <h3 className="name">{card.name}</h3>
                      {/* <input
                  name="name"
                  type="text"
                  className="input-text"
                  {...register("name", registerOptions.name)}
                  placeholder="My card name : "
                />  */}
                {errors?.name && <p className="error-msg"> {errors.name.message}</p>}
                </div>

                <div className="cta-section">
                  <div
                    onClick={() => {
                      navigate(`/card-details/${card.id_wic}`);
                    }}
                    className="cta-small-secondary details"
                  >
                    {t("editAddBt")}
                  </div>
                  <div
                    onClick={() => {
                      handleModalPreview(card.id_wic);
                    }}
                    className="cta-small-tertiary preview"
                  >
                    {t("previewBt")}
                  </div>
                  <div
                    onClick={() => {
                      handleModalPublish(card.id_wic);
                    }}
                    className="cta-small-primary publish"
                  >
                    {t("publishBt")}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>{t("noCards")}</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
