import axios from "axios";
import { database } from "./database";

export const addWineCard = async (name, user) => {
  const formData = new FormData();
  formData.append("id_abo", user.id_abo);
  formData.append("name", name);
  formData.append("session", user.session);

  try {
    const res = await axios.post(process.env.REACT_APP_WIB_SERVER + "/AddWineCard", formData);
    return res;
  } catch (error) {
    return error;
  }
};

export const addWineCardLanguage = async (data, user) => {
  const formData = new FormData();
  formData.append("id_wic", data.id_wic);
  formData.append("name", data.name);
  formData.append("url_flag", data.flag);
  formData.append("session", user.session);

  try {
    const res = await axios.post(process.env.REACT_APP_WIB_SERVER + "/AddWineCardLanguage", formData);
    return res;
  } catch (error) {
    return error;
  }
};
export const addWineCardTab = async (data, user) => {
  const formData = new FormData();

  formData.append("id_wic_lang", data.id_wic_lang);
  formData.append("name", data.name);
  formData.append("position", data.position);
  formData.append("session", user.session);

  try {
    const res = await axios.post(process.env.REACT_APP_WIB_SERVER + "/AddWineCardTab", formData);
    return res;
  } catch (error) {
    return error;
  }
};

export const addWineCardContentText = async (data, user) => {
  const formData = new FormData();
  formData.append("id_wic_tab", data.id_wic_tab);
  formData.append("content", data.content);
  formData.append("position", data.position);
  formData.append("session", user.session);

  try {
    const res = await axios.post(process.env.REACT_APP_WIB_SERVER + "/AddWineCardContentText", formData);
    return res;
  } catch (error) {
    return error;
  }
};
export const updateWineCardContentText = async (data, user) => {
  const formData = new FormData();

  formData.append("id_wic_content_text", data.id_wic_content_text);
  formData.append("content", data.content);
  formData.append("position", data.position);
  formData.append("session", user.session);

  try {
    const res = await axios.post(
      process.env.REACT_APP_WIB_SERVER + "/UpdateWineCardContentText",
      formData
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const addWineCardContentImage = async (data, user) => {
  const formData = new FormData();

  const file = data.file[0];
  let fileName = file.name;
  const maxLength = 40;
  if (fileName.length > maxLength) {
    fileName = fileName.slice(fileName.length - maxLength);
    const myNewFile = new File([file], fileName, {type: file.type});
    formData.append("file", myNewFile);
  }else{
    formData.append("file", file);
  }

  const url =
  process.env.REACT_APP_WIB_SERVER +
    "/AddWineCardContentImage?session=" +
    user.session +
    "&id_wic_tab=" +
    data.id_wic_tab +
    "&position=" +
    data.position;

  try {
    const res = await axios.post(url, formData);
    return res;
  } catch (error) {
    return error;
  }
};
export const updateWineCardContentImage = async (data, user) => {
  const formData = new FormData();
  const file = data.file[0];
  let fileName = file.name;
  const maxLength = 40;
  if (fileName.length > maxLength) {
    fileName = fileName.slice(fileName.length - maxLength);
    const myNewFile = new File([file], fileName, {type: file.type});
    formData.append("file", myNewFile);
  }else{
    formData.append("file", file);
  }



  const url =
  process.env.REACT_APP_WIB_SERVER +
    "/UpdateWineCardContentImage?session=" +
    user.session +
    "&id_wic_content_image=" +
    data.id_wic_content_image +
    "&position=" +
    data.position;

  try {
    const res = await axios.post(url, formData);
    return res;
  } catch (error) {
    return error;
  }
};
export const downloadWineCardContentImage = async (
  id_wic_content_image,
  user
) => {
  return fetch(
    process.env.REACT_APP_WIB_SERVER +
      "/DownloadWineCardContentImage?session=" +
      user.session +
      "&id_wic_content_image=" +
      id_wic_content_image,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/jpeg",
      },
    }
  )
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      return url;
    });
};

export const getWineCard = async (user) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_WIB_SERVER + "/GetWineCard?session=" + user.session + "&id_abo=" + user.id_abo
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const updateWineCard = async (data, user) => {
  const formData = new FormData();
  formData.append("id_wic", data.id_wic);
  formData.append("name", data.name);
  formData.append("session", user.session);

  try {
    const res = await axios.post(process.env.REACT_APP_WIB_SERVER + "/UpdateWineCard", formData);
    return res;
  } catch (error) {
    return error;
  }
};

export const getWineCardLanguage = async (user, id_wic) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_WIB_SERVER +
        "/GetWineCardLanguage?session=" +
        user.session +
        "&id_wic=" +
        id_wic
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const updateWineCardLanguage= async (data, user) => {
  const formData = new FormData();
  //const tabsName = JSON.stringify(data.tabsname);
  formData.append("id_wic_lang", data.id_wic_lang);
  formData.append("tabsname", JSON.stringify(data.tabsname))
  formData.append("session", user.session);

  try {
    const res = await axios.post(process.env.REACT_APP_WIB_SERVER + "/UpdateWineCardLanguage", formData);
    return res;
  } catch (error) {
    return error;
  }
};

export const getWineCardTab = async (user, id_wic_lang) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_WIB_SERVER +
        "/GetWineCardTab?session=" +
        user.session +
        "&id_wic_lang=" +
        id_wic_lang
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getWineCardContent = async (user, id_wic_tab) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_WIB_SERVER +
        "/GetWineCardContent?session=" +
        user.session +
        "&id_wic_tab=" +
        id_wic_tab
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getWineCardContentImage = async (user, id_wic_tab) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_WIB_SERVER +
        "/GetWineCardContentImage?session=" +
        user.session +
        "&id_wic_tab=" +
        id_wic_tab
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteWineCard = async (user, id_wic) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_WIB_SERVER +
        "/DeleteWineCard?session=" +
        user.session +
        "&id_abo=" +
        user.id_abo +
        "&id_wic=" +
        id_wic
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const deleteWineCardLanguage = async (user, id_wic_lang) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_WIB_SERVER +
        "/DeleteWineCardLanguage?session=" +
        user.session +
        "&id_abo=" +
        user.id_abo +
        "&id_wic_lang=" +
        id_wic_lang
    );
    return res;
  } catch (error) {
    return error;
  }
};
