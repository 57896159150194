import React, { useEffect, useContext, useState } from "react";
import { cardLanguages } from "../utils/cardLanguages";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";


import UserContext from "../services/UserContext";
import trashIcon from "../assets/images/trash.png";
import wineIcon from "../assets/images/ico_accueil__.png";
import estateIcon from "../assets/images/propriete.png";
import servingIcon from "../assets/images/services.png";
import traceabilityIcon from "../assets/images/tracabilite.png";
import documentsIcon from "../assets/images/documents.png";
import {
  updateWineCard,
  getWineCardTab,
  deleteWineCard,
  getWineCardLanguage
} from "../services/CardService";
import ModalConfirmation from "../components/ModalConfirmation";

const NewCardView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentUser] = useContext(UserContext);
  const [newCardObj, setNewCardObj] = useState();
  const [tabsCompleted, setTabsCompleted] = useState();
  const [tabsNameCompleted, setTabsNameCompleted] = useState(false);
  const [toggleModalDeleteCard, setToggleModalDeleteCard] = useState(false);

  useEffect(() => {
    const newCardJSON = localStorage.getItem("newWineCard");

    if (newCardJSON) {
      setNewCardObj(JSON.parse(newCardJSON));
    }
  }, []);

  useEffect(() => {
    const fetchTab = async () => {
      let result = await getWineCardTab(currentUser, newCardObj.id_wic_lang);
      setTabsCompleted(result.data);
      let resultLang = await getWineCardLanguage(currentUser, newCardObj.id_wic);
      const tabsName = resultLang.data[0].tabsname;
      if(tabsName !== "")  setTabsNameCompleted(true)
    };

    if (newCardObj) {
      setValue("name", newCardObj.name);
      fetchTab();
    }
  }, [newCardObj]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const registerOptions = {
    name: { required: t('fieldRequired') },
  };

  const getLanguage = (type) => {
    if (type === "name") {
      return newCardObj.language;
    } else {
      const langObj = cardLanguages.filter(
        (lang) => lang.name == newCardObj.language
      )[0];

      return langObj.flag;
    }
  };

  const getTabCompleted = (position) => {
    if (tabsCompleted) {
      const tab = tabsCompleted.filter((obj) => obj.position == position && obj.name !== "" );

      return tab.length > 0 ? "is-valid" : "";
    }
  };

  const onSubmit = async (data) => {
    newCardObj.name = data.name;
    const result = await updateWineCard(newCardObj, currentUser);
    const idCard = result.data.id;
    navigate(`/card-details/${idCard}`);
  };

  const handleDeleteCard = () => {
    setToggleModalDeleteCard(true);
  };

  const closeModalDeleteCard = async (toDelete) => {
    setToggleModalDeleteCard(false);
    if (toDelete) {
      await deleteWineCard(currentUser, newCardObj.id_wic);
      navigate("/dashboard");
    }
  };

  return (
    <>
      <div className="app-card wrapper">
        {toggleModalDeleteCard && (
          <ModalConfirmation
            infoText={t("confirmDelete")}
            closeModal={closeModalDeleteCard}
          />
        )}

        <h1 className="title-h1">{t("newWineCard")}</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="language-section">
            {newCardObj && (
              <h3 className="title-h3">
                Card language :
                <span className="lang">
                  <span className={"flag " + getLanguage("flag")}></span>
                  {getLanguage("name")}
                </span>
              </h3>
            )}
          </div>

          <div className="name-section">
            <h3 className="title-h3"> {t("myCardName")}</h3>

            <input
              name="name"
              type="text"
              className="input-text"
              {...register("name", registerOptions.name)}
              placeholder="My card name : "
            />
            <p className="error-msg">{errors?.name && errors.name.message}</p>
          </div>

          <h3 className="title-categories title-h3">
            {t("fillCategoriesTxt")}
          </h3>

          <ul className="categories">
            <li
              onClick={() => navigate("./wine")}
              className={getTabCompleted(1)}
            >
              <img src={wineIcon} /> {t("tabWine")}{" "}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./estate")}
              className={getTabCompleted(2)}
            >
              <img src={estateIcon} /> {t("tabEstate")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./service")}
              className={getTabCompleted(3)}
            >
              <img src={servingIcon} />
              {t("tabService")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./Traceability")}
              className={getTabCompleted(4)}
            >
              <img src={traceabilityIcon} />
              {t("tabTraceability")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./documents")}
              className={getTabCompleted(5)}
            >
              <img src={documentsIcon} />
              {t("tabDocuments")}
              <span className="checkmark"></span>
            </li>
            <li
              onClick={() => navigate("./menu")}
              className={"menu " + (tabsNameCompleted ? "is-valid" : "")}
            >
              {t('menuTabs')}
              <span className="checkmark"></span>
            </li>
          </ul>

          <div className="section-cta">
            <div
              className="cancel-bt cta-tertiary"
              onClick={() => handleDeleteCard()}
            >
              <img src={trashIcon} />
              {t("cancel")}
            </div>
            <input
              className="create-bt cta-primary"
              type="submit"
              value={t("saveCard")}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default NewCardView;
