import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { login } from "../services/AuthService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext from "../services/UserContext";
import { cardLanguages } from "../utils/cardLanguages";
import { addWineCard, addWineCardLanguage } from "../services/CardService";

const ModalNewCard = ({ closeModal }) => {
  const { t } = useTranslation();
  const [errorLogin, setErrorLogin] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [currentUser] = useContext(UserContext);
  const navigate = useNavigate();

  const onSubmit = async (infos) => {
    let result = await addWineCard(infos.name, currentUser);

    if (result?.data) {
      const newCardObj = {};
      newCardObj.language = infos.language;
      newCardObj.name = infos.name;
      newCardObj.id_wic = result.data.id;
      newCardObj.flag = cardLanguages.filter(
        (lang) => lang.name === newCardObj.language
      )[0].flag;

      let resultLang = await addWineCardLanguage(newCardObj, currentUser);

      if (resultLang?.data) {
        newCardObj.id_wic_lang = resultLang.data.id;
        localStorage.setItem("newWineCard", JSON.stringify(newCardObj));
        navigate("/new-card");
      }
    }
  };
  const registerOptions = {
    name: { required: t('fieldRequired') },
  };

  return (
    <div className="modal-bg modal-new-card">
      <div className="modal new-card">
        <div
          className="close-modal"
          onClick={() => {
            closeModal();
          }}
        ></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="title-h1"> {t("newWineCard")}</h1>
          <div className="language-section">
            <h3 className="title-h3"> {t("chooseCardLanguage")}</h3>

            <select {...register("language")}>
              {cardLanguages.map((language, index) => (
                <option key={index} value={language.name}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>

          <div className="name-section">
            <h3 className="title-h3">{t("chooseCardName")}</h3>

            <input
              name="name"
              type="text"
              className="input-text"
              {...register("name", registerOptions.name)}
              placeholder="My card name"
            />
            <p className="error-msg">{errors?.name && errors.name.message}</p>
          </div>

          <div className="section-cta">
            <div
              className="cancel-bt cta-tertiary"
              onClick={() => {
                closeModal();
              }}
            >
              {t("cancel")}
            </div>
            <input
              className="create-bt cta-primary"
              type="submit"
              value={t("createCard")}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalNewCard;
