
    export const cardLanguages = [
      {
      "name": "English",
      "flag": "gb",
      "code": "en"
    },
    {
      "name": "Mandarin",
      "flag": "cn",
      "code": "zh"
    },
    {
      "name": "Spanish",
      "flag": "es",
      "code": "es"
    },
    {
      "name": "Hindi",
      "flag": "in",
      "code": "hi"
    },
    {
      "name": "Arabic",
      "flag": "sa",
      "code": "ar"
    },
    {
      "name": "Bengali",
      "flag": "bd",
      "code": "bn"
    },
    {
      "name": "Portuguese",
      "flag": "pt",
      "code": "pt"
    },
    {
      "name": "Russian",
      "flag": "ru",
      "code": "ru"
    },
    {
      "name": "Japanese",
      "flag": "jp",
      "code": "ja"
    },
    {
      "name": "German",
      "flag": "de",
      "code": "de"
    },
    {
      "name": "Javanese",
      "flag": "id",
      "code": "jv"
    },
    {
      "name": "Korean",
      "flag": "kr",
      "code": "ko"
    },
    {
      "name": "French",
      "flag": "fr",
      "code": "fr"
    },
    {
      "name": "Turkish",
      "flag": "tr",
      "code": "tr"
    },
    {
      "name": "Vietnamese",
      "flag": "vn",
      "code": "vi"
    },

    {
      "name": "Italian",
      "flag": "it",
      "code": "it"
    },

      {
      "name": "Turkmen",
      "flag": "tm",
      "code": "tk"
      },
      {
      "name": "Urdu",
      "flag": "pk",
      "code": "ur"
      },
      {
      "name": "Ukrainian",
      "flag": "ua",
      "code": "uk"
      },
      {
      "name": "Min Nan Chinese",
      "flag": "tw",
      "code": "nan"
      },
      {
      "name": "Polish",
      "flag": "pl",
      "code": "pl"
      },
      {
      "name": "Thai",
      "flag": "th",
      "code": "th"
      },

      {
      "name": "Indonesian",
      "flag": "id",
      "code": "id"
      },
      {
      "name": "Dutch",
      "flag": "nl",
      "code": "nl"
      },
      {
      "name": "Romanian",
      "flag": "ro",
      "code": "ro"
      }
    ]

