import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import topIcon from "../assets/images/tracabilite.png";
import UserContext from "../services/UserContext";
import { useNavigate } from "react-router-dom";
import {
  addWineCardTab,
  getWineCardTab,
  getWineCardContent,
  addWineCardContentText,
  updateWineCardContentText,
} from "../services/CardService";

const TraceabilityView = () => {
  const positionTab = 4;
  const tabName = "traceability";
  const [currentUser] = useContext(UserContext);
  const [newCardObj, setNewCardObj] = useState();
  const [contentList, setContentList] = useState([]);

  const [mode, setMode] = useState("creation");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const newCardJSON = localStorage.getItem("newWineCard");

    if (newCardJSON) {
      setNewCardObj(JSON.parse(newCardJSON));
    }
  }, []);

  useEffect(() => {
    if (newCardObj) {
      fetchTabContent();
    }
  }, [newCardObj]);

  const fetchTabContent = async () => {
    let resultTab = await getWineCardTab(currentUser, newCardObj.id_wic_lang);

    const resultCurrentTab = resultTab.data.filter(
      (obj) => obj.position == positionTab
    );

    if (resultCurrentTab.length > 0) {
      setMode("edition");

      let resultContent = await getWineCardContent(
        currentUser,
        resultCurrentTab[0].id_wic_tab
      );

      getValuesForm(resultContent.data);

      setContentList(resultContent.data);
    }else{
      setWineValues(resultTab)
    }
  };

  const getValuesForm = (resultContent) => {
    for (let index = 0; index < resultContent.length; index++) {
      const value = resultContent[index];
      const position = value.position;

      setValue("pos" + position, value.content);
    }
  };

  const setWineValues = async (resultTab) => {

    const firstTab = resultTab.data.filter((obj) => obj.position === 1);
 
    if (firstTab.length > 0) {
      let resultContent = await getWineCardContent(
        currentUser,
        firstTab[0].id_wic_tab
      );
      setValue("pos1", resultContent.data.filter(obj => obj.position === 1)[0].content);
      setValue("pos2", resultContent.data.filter(obj => obj.position === 2)[0].content);
      setValue("pos3", resultContent.data.filter(obj => obj.position === 3)[0].content);

    }
  };

  const onSubmit = async (infos) => {
    newCardObj.position = positionTab;

    let result;

    if (mode === "creation") {
      newCardObj.name = tabName;
      result = await addWineCardTab(newCardObj, currentUser);

      newCardObj.id_wic_tab = result.data.id;
    } else {
      result = await getWineCardTab(currentUser, newCardObj.id_wic_lang);
      newCardObj.id_wic_tab = result.data.filter(
        (obj) => obj.position == positionTab
      )[0].id_wic_tab;
    }

    let index = 0;
    for (const key in infos) {
      index++;
      const value = infos[key];

      newCardObj.content = value;
      newCardObj.position = index;

      if (mode === "creation") {
        addWineCardContentText(newCardObj, currentUser);
      } else {
        newCardObj.id_wic_content_text = contentList.filter(
          (obj) => obj.position === index
        )[0].id_wic_content_text;

        updateWineCardContentText(newCardObj, currentUser);
      }
    }
    navigate("./../");
  };

  const registerOptions = {
    pos1: { required: t('fieldRequired') },
    pos2: { required: t('fieldRequired') },
    pos3: { required: t('fieldRequired') },
    pos4: { required: t('fieldRequired') },
  };

  return (
    <div id="page">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-content">
          <div className="content-top  is-traceability">
            <div className="wine-top-infos">
              <h3 className="wine-name">
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("chateau")}
                  {...register("pos1", registerOptions.pos1)}
                />
                <span className="error-msg">
                  {errors?.pos1 && errors.pos1.message}
                </span>
              </h3>
              <div className="wine-infos-1">
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("aoc")}
                  {...register("pos2", registerOptions.pos2)}
                />
                <span className="error-msg">
                  {errors?.pos2 && errors.pos2.message}
                </span>
              </div>
              <div className="wine-infos-2">
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("wineinfo")}
                  {...register("pos3", registerOptions.pos3)}
                />
                <span className="error-msg">
                  {errors?.pos3 && errors.pos3.message}
                </span>
              </div>
            </div>
            <div className="caption-image">
              <img src={topIcon} />
              <h5>
                <input
                  type="text"
                  className="input-text"
                  placeholder={t("traceability")}
                  {...register("pos4", registerOptions.pos4)}
                />
                <span className="error-msg">
                  {errors?.pos4 && errors.pos4.message}
                </span>
              </h5>
            </div>
          </div>

          <div className="validate-section">
            <div
              className="cancel-bt cta-tertiary"
              onClick={() => navigate("./../")}
            >
              Cancel
            </div>
            <input
              className="validate-bt cta-primary"
              type="submit"
              value="Validate"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TraceabilityView;
