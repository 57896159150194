import axios from "axios";

export const login = async (infos) => {
  const formData = new FormData();
  formData.append("login", infos.login);
  formData.append("password", infos.password);
  formData.append("mode", "PASSWORD");

  console.log('wib server: '+process.env.REACT_APP_WIB_SERVER)
  
  try {
    const res = await axios.post(
      process.env.REACT_APP_WIB_SERVER+"/LoginUser",
      formData
    );

    const session = res.data.session;
    if (session) {
      localStorage.setItem("user", JSON.stringify(res.data));
    }

    return res;
  } catch (error) {
    return error;
  }
};

export const isAuthenticated = () => {
  const user = localStorage.getItem("user");
  if (!user) {
    return null;
  }
  return JSON.parse(user);
};
