import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ url }) => {
  const navigate = useNavigate();

  return (
    <div className="back-bt" onClick={() => navigate(url)}>
      <div className="left-arrow"></div>
    </div>
  );
};

export default BackButton;
