import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import UserContext from "../services/UserContext";
import { cardLanguages } from "../utils/cardLanguages";

import wineIcon from "../assets/images/ico_accueil__.png";
import estateIcon from "../assets/images/propriete.png";
import servingIcon from "../assets/images/services.png";
import traceabilityIcon from "../assets/images/tracabilite.png";
import documentsIcon from "../assets/images/documents.png";
import { useNavigate } from "react-router-dom";
import {

  updateWineCardLanguage,
  getWineCardLanguage,

} from "../services/CardService";

const MenuView = () => {
  const [currentUser] = useContext(UserContext);
  const [newCardObj, setNewCardObj] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const newCardJSON = localStorage.getItem("newWineCard");

    if (newCardJSON) {
      const newCard = JSON.parse(newCardJSON);
      const codeLang = cardLanguages.filter((obj) => obj.name == newCard.language)[0].code;
      newCard.codeLang = codeLang;
      setNewCardObj(newCard);
    }
  }, []);

  useEffect(() => {
    if (newCardObj) {
      fetchTabContent();
    }
  }, [newCardObj]);


  const fetchTabContent = async () => {
    const getLang = await getWineCardLanguage(currentUser, newCardObj.id_wic);
    const currentLang = getLang.data.filter(obj => obj.id_wic_lang == newCardObj.id_wic_lang)[0]
    const tabs = currentLang.tabsname

    if(tabs !== "") getValuesForm(JSON.parse(tabs))
  };

  const getValuesForm = (resultContent) => {
    for (let index = 0; index < resultContent.length; index++) {
      const value = resultContent[index];
      const position = value.position;
      setValue("pos" + position, value.name);
    }
  };

  const registerOptions = {
    pos1: { required: t("fieldRequired") },
    pos2: { required: t("fieldRequired") },
    pos3: { required: t("fieldRequired") },
    pos4: { required: t("fieldRequired") },
    pos5: { required: t("fieldRequired") },

  };

  const onSubmit = async (infos) => {

   const tabsName =  Object.keys(infos).map((obj, index) =>{
      const keyName = `pos${index + 1}`
      return {name: infos[keyName], position : index + 1 }
    })

    newCardObj.tabsname = tabsName

    updateWineCardLanguage(newCardObj, currentUser);
    navigate("./../");
  };


  return (
    <div className="app-menu wrapper">
      <h1 className="title-h2">{t('chooseTabName')}</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="form-login">
        <ul className="menuList">
          <li>
            <div className="icon">
              <img src={wineIcon} />
            </div>
            <input
              type="text"
              className="input-text"
              placeholder={t("tabWine")}
              {...register("pos1", registerOptions.pos1)}
            />

          </li>
          <span className="error-msg">
              {errors?.pos1 && errors.pos1.message}
            </span>
          <li>
            <div className="icon">
              <img src={estateIcon} />
            </div>
            <input
              type="text"
              className="input-text"
              placeholder={t("tabEstate")}
              {...register("pos2", registerOptions.pos2)}
            />

          </li>
          <span className="error-msg">
              {errors?.pos2 && errors.pos2.message}
            </span>
          <li>
            <div className="icon">
              <img src={servingIcon} />
            </div>
            <input
              type="text"
              className="input-text"
              placeholder={t("tabService")}
              {...register("pos3", registerOptions.pos3)}
            />

       
          </li>
          <span className="error-msg">
              {errors?.pos3 && errors.pos3.message}
            </span>
          <li>
            <div className="icon">
              <img src={traceabilityIcon} />
            </div>
            <input
              type="text"
              className="input-text"
              placeholder={t("tabTraceability")}
              {...register("pos4", registerOptions.pos4)}
            />


          </li>
          <span className="error-msg">
              {errors?.pos4 && errors.pos4.message}
            </span>
          <li>
            <div className="icon">
              <img src={documentsIcon} />
            </div>
            <input
              type="text"
              className="input-text"
              placeholder={t("tabDocuments")}
              {...register("pos5", registerOptions.pos5)}
            />

 
          </li>
          <span className="error-msg">
              {errors?.pos5 && errors.pos5.message}
            </span>
        </ul>

        <div className="validate-section">
          <div
            className="cancel-bt cta-tertiary"
            onClick={() => navigate("./../")}
          >
            {t("cancel")}
          </div>
          <input
            className="validate-bt cta-primary"
            type="submit"
            value= {t("validate")}
          />
        </div>
      </form>
    </div>
  );
};

export default MenuView;
