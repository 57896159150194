import axios from "axios";
import { database } from "./database";
import {
  getWineCardContent,
  downloadWineCardContentImage,
} from "./CardService";
import { cardLanguages } from "../utils/cardLanguages";
import { wineHtml } from "../html/wine";
import { estateHtml } from "../html/estate";
import { serviceHtml } from "../html/service";
import { traceabilityHtml } from "../html/traceability";
import { documentsHtml } from "../html/documents";
import bottleIcon from "../assets/images/bottle-wine.png";

const positionTabs = [
  "index",
  "propriete",
  "service",
  "tracabilite",
  "documents",
];

export const publishCard = async (card, user) => {
  const cardLangs = card.lang;

  const generateHtml = (position, contentTab, lang, tabsName) => {
    const file = positionTabs[position];

    switch (file) {
      case "index": {
        return {
          name: "index.html",
          content: wineHtml(contentTab, cardLangs, lang, tabsName),
        };
      }
      case "propriete": {
        return {
          name: "propriete.html",
          content: estateHtml(contentTab, cardLangs, lang, tabsName),
        };
      }
      case "service": {
        return {
          name: "service.html",
          content: serviceHtml(contentTab, cardLangs, lang, tabsName),
        };
      }
      case "tracabilite": {
        return {
          name: "tracabilite.html",
          content: traceabilityHtml(contentTab, cardLangs, lang, tabsName),
        };
      }
      case "documents": {
        return {
          name: "documents.html",
          content: documentsHtml(contentTab, cardLangs, lang, tabsName),
        };
      }
    }
  };

  const publishHtml = async (contentJSON) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_PUBLISH_URL + "/Publish/",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          mode: "no-cors",
          data: contentJSON,
        }
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const createDefaultImage = async (path) => {
    console.log('CREATE DEF')
    const fileReader = new FileReader();
    fetchBlob(bottleIcon)
      .then((blob) => {
        fileReader.readAsDataURL(blob);
        fileReader.onload = () => {
          const base64result = fileReader.result.split(",")[1];
          publishImage(base64result, 'default-wineinblock.png', path);
        };
      })
      .catch((error) => console.error(error));
   
  }

  const generateImages = async (listImages, cardId, user, contenJSON) => {
    for (let i = 0; i < Object.keys(listImages).length; i++) {
      const lang = Object.keys(listImages)[i];

      const mainImage = listImages[lang].filter(obj => obj.position === 4)
      if(mainImage.length === 0 ){
        createDefaultImage(`${cardId}/${lang}/images`);
      }

      for (let j = 0; j < listImages[lang].length; j++) {
        const imageId = listImages[lang][j].id_wic_content_image;
        const fileName = listImages[lang][j].filename;
        const path = `${cardId}/${lang}/images`;
        const blob = await downloadWineCardContentImage(imageId, user);
        const base64 = await getBase64FromUrl(blob);
        const base64result = base64.split(",")[1];

        if (base64result) {
          publishImage(base64result, fileName, path);
        }
      }
    }
    return;
  };

  const cardId = card.id_wic;
  const contentJSON = {};
  contentJSON[cardId] = {};
  const cardsLang = card.lang;
  const listImages = {};


  for (let i = 0; i < cardsLang.length; i++) {

    let tabsName = cardsLang[i].tabsname;
    tabsName = tabsName !== '' ? JSON.parse(tabsName) : null;

    const codeLang = cardLanguages.filter(
      (obj) => obj.flag == cardsLang[i].url_flag
    )[0].code;
    
    contentJSON[cardId][codeLang] = [];

    listImages[codeLang] = [];

    for (let j = 0; j < positionTabs.length; j++) {
      let infoHtml;

      if (cardsLang[i].tabs.length > 0) {
        const tab = cardsLang[i].tabs.filter((obj) => obj.position == j + 1);
        if (tab.length > 0) {
          const contentTab = await getWineCardContent(user, tab[0].id_wic_tab);
          infoHtml = generateHtml(j, contentTab.data, codeLang, tabsName);
          listImages[codeLang].push(
            ...contentTab.data.filter((obj) => obj.hasOwnProperty("filename"))
          );
        } else {
          infoHtml = generateHtml(j, null, codeLang, tabsName);
        }
      } else {
        infoHtml = generateHtml(j, null, codeLang, tabsName);
      }

      contentJSON[cardId][codeLang].push(infoHtml);
    }
  }

  try {
    publishHtml(contentJSON);
    await generateImages(listImages, cardId, user, contentJSON);
    return { status: "success" };
  } catch (error) {
    return { status: "failed", error: error };
  }
};

export const publishImage = async (base64result, imageName, path) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_PUBLISH_URL + "/PublishImages/",
      {
        image: base64result,
        imageName: imageName,
        path: path,
      }
    );

    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const createZip = async (idCard) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_PUBLISH_URL + "/CreateZip/",
      {
        idCard: idCard,
      }
    );

    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const fetchBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

export const sendEmail = (idCard) => {
  console.log('idCard', idCard)
  const user = localStorage.getItem("user")
  const userJson = JSON.parse(user);
  // console.log('user: '+user)
  // console.log('user email: '+userJson.email)
  const params = {
    to: 'contact@wineinblock.com',
    subject: 'New publication',
    text: `A new publication has been posted for Winecard number: ${idCard}, username `+userJson.email+`.`,
  };

  axios.post(process.env.REACT_APP_PUBLISH_URL + '/SendEmail', params)
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.error(error);
    });
}
